// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-books-tsx": () => import("./../../../src/pages/books.tsx" /* webpackChunkName: "component---src-pages-books-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-book-caption-template-tsx": () => import("./../../../src/templates/book-caption-template.tsx" /* webpackChunkName: "component---src-templates-book-caption-template-tsx" */),
  "component---src-templates-book-template-tsx": () => import("./../../../src/templates/book-template.tsx" /* webpackChunkName: "component---src-templates-book-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag-template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

